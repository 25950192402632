import './SearchSuggestionElement';
import { TemplateElement } from '../../01-base/TemplateElement';
import style from '../SvgSprite/SvgIcon.css';

class SearchFormElement extends TemplateElement {
    properties() {
        return {
            placeholder: '',
            autofocus: false,
            keywords: [],
            searchstring: '',
            action: '',
            mobile: false,
            url: '',
            rootNode: '',
        };
    }

    constructor() {
        super(null, { autoRender: false, shadowRender: false, styles: [style] });
        this.currentLI = -1;
        this.currentInputText = '';
    }

    events() {
        return {
            '[ref="suggestion"]': {
                input: () => {
                    if (this.$refs.suggestion.value.length >= 2) {
                        this.getAutoSuggestion(this.$refs.suggestion.value);
                        this.currentInputText = this.$refs.suggestion.value;
                        this.currentLI = -1;
                    } else {
                        this.$refs.suggestionElement.keywords = [];
                        this.currentInputText = '';
                        this.currentLI = -1;
                    }
                },
            },
            this: {
                keydown: event => {
                    if (document.getElementById('suggestionUlList') !== null) {
                        const suggestionListItems = document.querySelectorAll('.suggestion-item-list li');
                        if (event.key === 'ArrowUp') {
                            if (this.currentLI > 0) {
                                this.setHightlightClass(-1, suggestionListItems);
                            } else if (this.currentLI === 0) {
                                suggestionListItems[this.currentLI].classList.remove('hightlighted-suggestion');
                                this.currentLI = this.currentLI - 1;
                                this.$refs.suggestion.value = this.currentInputText;
                            } else if (this.currentLI === -1) {
                                this.currentLI = suggestionListItems.length - 1;
                                suggestionListItems[this.currentLI].classList.add('hightlighted-suggestion');
                                this.$refs.suggestion.value = suggestionListItems[this.currentLI].getAttribute(
                                    'data-suggestion',
                                );
                            }
                        }
                        if (event.key === 'ArrowDown') {
                            if (this.currentLI === -1) {
                                this.currentLI = this.currentLI + 1;
                                suggestionListItems[this.currentLI].classList.add('hightlighted-suggestion');
                                this.$refs.suggestion.value = suggestionListItems[this.currentLI].getAttribute(
                                    'data-suggestion',
                                );
                            } else if (this.currentLI < suggestionListItems.length - 1) {
                                this.setHightlightClass(+1, suggestionListItems);
                            } else if (this.currentLI === suggestionListItems.length - 1) {
                                suggestionListItems[this.currentLI].classList.remove('hightlighted-suggestion');
                                this.currentLI = -1;
                                this.$refs.suggestion.value = this.currentInputText;
                            }
                        }
                    }
                },
            },
        };
    }

    async getAutoSuggestion(p1) {
        try {
            let response = await fetch(this.url + '/.rest/suggest/v1/?q=' + p1 + '&fq=' + this.rootNode);
            let object = await response.json();
            this.$refs.suggestionElement.keywords = object.terms;
        } catch (e) {}
    }
    setHightlightClass(number, suggestionListItems) {
        suggestionListItems[this.currentLI].classList.remove('hightlighted-suggestion');
        this.currentLI = this.currentLI + number;
        suggestionListItems[this.currentLI].classList.add('hightlighted-suggestion');
        this.$refs.suggestion.value = suggestionListItems[this.currentLI].getAttribute('data-suggestion');
    }

    template() {
        return `
            <form action="${this.action}" method="get">
            <div class="form-control-group" ref="group">
                <div class="search-content d-flex align-items-center pb-lg-1">
                <input ref="suggestion" class="${
                    window.screen.width <= 1024 && this.mobile ? 'd-none ' : ''
                }form-control font-italic" type="text" name="search" placeholder="${this.placeholder}" value="${
            this.searchstring
        }" autofocus="${this.autofocus}" autocomplete="off">
                <button type="submit" class="search-button p-0" data-search-toggle>
                    <svg-icon name="lupe" class="search-icon ml-1"></svg-icon>
                </button>
            </div>
            </div>
            </form>
            <search-suggestion-element ref="suggestionElement" action="${this.action}" root-node="${
            this.rootNode
        }" class="position-relative d-block"></search-suggestion-element>
`;
    }
}

customElements.define('search-form-element', SearchFormElement);
